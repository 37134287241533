import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
import ServicesHome from "../Services/ServicesHome_2";

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);

  return (
    <div className="bg-[#0F48C7]">
      <div className="md:w-[98%] mx-auto py-12 text-center ">
        <h2 className="text-center py-4 text-white">{rpdata?.labels?.general?.titleServices}</h2>
        <ButtonContent btnStyle="text-[#0F48C7] bg-[#FFD700] hover:bg-[#FFDF85]" btnName="view all services" btnLink={'services'} />
        <div className="flex flex-wrap items-center justify-center">
          {rpdata?.dbServices?.map((item, index) => {
            return (
              <ServicesHome
                key={index}
                bgImg={item.description[0].img}
                serviceName={item.name}
                serviceText={item.description[0].text.substring(0, 160) + "..."}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ContentServicesHome;
